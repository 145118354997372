import React, { useEffect, useState } from "react";
import style from "./psilideo-plague.module.css";

import PsilideoImage from "../../../images/psilide-colored.png";

import TotalInformations from "./total-informations/total-informations";

function PsilideoPlague({ plague, infestation }) {
  const [samples, setSamples] = useState([]);
  const [unsheet, setUnsheet] = useState();
  
  const [levelInfestation, setLevelInfestation] = useState("");

  useEffect(() => {
    const amostra = Array.from({ length: 5 }).map((_, index) => ({
      planta: index + 1,
      percentualEggs: 0,
      percentualNinfas: 0,
      percentualNinfasParasited: 0,
    }));
    setSamples(amostra);
  }, []);

  const handleChangeEggs = (index, value) => {
    const updatedAmostras = [...samples];
    updatedAmostras[index].percentualEggs = value;
    setSamples(updatedAmostras);
  };

  const handleChangeNinfas = (index, value) => {
    const updatedAmostras = [...samples];
    updatedAmostras[index].percentualNinfas = value;
    setSamples(updatedAmostras);
  };

  const handleChangeNinfasParasited = (index, value) => {
    const updatedAmostras = [...samples];
    updatedAmostras[index].percentualNinfasParasited = value;
    setSamples(updatedAmostras);
  };

  const handleChangeInfestation = (information) => {
    let info = information;

    infestation(info);

  }


  return (
    <div className={style["beetle-plague"]}>
      <div className={style["card"]}>
        <div className={style["title-card"]}>Amostras</div>
        <div className={style["sample-plague"]}>
          <img src={PsilideoImage} />
          <div className={style["name-sample-plague"]}>
            <div>{plague.name}</div>
            <div>{plague.scientific}</div>
          </div>
        </div>

        <div className={style["samples"]}>
          {samples.map((sample, index) => (
            <div className={style["collected-sample-title"]}>
              <div className={style["collected-sample-plant"]}>
                <div className={style["collected-sample-plant-number"]}>
                  {index + 1}
                </div>
                <div className={style["collected-sample-plant-text"]}>
                  Planta
                </div>
              </div>
              <div className={style["psilideo-informations"]}>
                <div className={style["information-unsheet"]}>
                  <div> N° de </div>
                  <div> Ovos </div>
                  <div>
                    <input
                      value={sample.percentualEggs}
                      className={style["input"]}
                      type="number"
                      onChange={(e) => handleChangeEggs(index, e.target.value)}
                    ></input>
                  </div>
                </div>

                <div className={style["information-unsheet"]}>
                  <div> N° de Ninfas </div>
                  <div> Vivas </div>

                  <div>
                    <input
                      value={sample.percentualNinfas}
                      className={style["input"]}
                      type="number"
                      onChange={(e) =>
                        handleChangeNinfas(index, e.target.value)
                      }
                    ></input>
                  </div>
                </div>

                <div className={style["information-unsheet"]}>
                  <div> N° de Ninfas </div>
                  <div> Parasitadas </div>

                  <div>
                    <input
                      value={sample.percentualNinfasParasited}
                      className={style["input"]}
                      type="number"
                      onChange={(e) =>
                        handleChangeNinfasParasited(index, e.target.value)
                      }
                    ></input>
                  </div>
                </div>
                
              </div>
            </div>
          ))}
        </div>
      </div>

      <TotalInformations samples={samples} infestation={handleChangeInfestation} />

    </div>
  );
}

export default PsilideoPlague;
