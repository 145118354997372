import React, { useEffect, useState } from "react";
import style from "./body-dashboard.module.css";

import "swiper/swiper-bundle.css";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
  FreeMode,
} from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/parallax";

import axios from "axios";

import { RedBalls, GreenBalls, YellowBalls } from "./balls/balls";
import Camouflage from "./camouflage/camouflage";
import Card from "./card/card";

import caterpillar from "../../images/caterpillar.png";
import moth from "../../images/moth.png";
import psilide from "../../images/psilide.png";
import beetle from "../../images/beetle.png";
import { toast, ToastContainer } from "react-toastify";

let aux = 0;
let aux2 = 0;

function BodyDashboard() {
  // const [plots, setPlots] = React.useState([]);
  const [occurrences, setOccurrences] = React.useState([]);
  const [occurrencesSort, setOccurrencesSort] = React.useState([]);

  const [validator, setValidator] = React.useState(true);
  const [camouflage, setCamouflage] = React.useState(true);
  const [showCards, setShowCards] = React.useState(false);
  const [enablePlague, setEnablePlague] = React.useState(false);
  const [dadoInPlots, setDadoInPlots] = React.useState(false);

  const [hectar, setHectar] = React.useState(0);
  const [alto, setAlto] = React.useState(0);
  const [medio, setMedio] = React.useState(0);
  const [baixo, setBaixo] = React.useState(0);
  const [plagueSelected, setPlagueSelected] = React.useState("");
  const [plagues, setPlagues] = React.useState([]);
  const [municipality, setMunicipality] = React.useState([]);
  const [plots, setPlots] = React.useState([]);

  const plague_names = [
    { name: "Lagarta Verde", scientific: "Psorocampa Denticulata" },
    { name: "Lagarta Parda", scientific: "Thyrinteina Arnobia" },
    { name: "Lagarta Cachorrinho", scientific: "Eupseudosoma Spp" },
    { name: "Glena", scientific: "Glena Bipennaria" },
    { name: "Lagarta Marrom", scientific: "Oxydia Vesulia" },
    { name: "Sarsina", scientific: "Sarsina Spp" },
    { name: "Dyrphia", scientific: "Dyrphia Rosacordis" },
    { name: "Nistalea", scientific: "Nystalea Nyseus" },
    { name: "Hylesia", scientific: "Hylesia Sp" },
    { name: "Sabulodes", scientific: "Sabulodes Caberata" },
    { name: "Psilideo de Concha", scientific: "Glycaspis Brimblecombei" },
    { name: "Percevejo Bronzeado", scientific: "Thaumastocoris Peregrinus" },
    { name: "Besouro Amarelo", scientific: "Costalimaita Ferruginea" },
    { name: "Besouro Azul", scientific: "Sternocolaspis Quatuord" },
  ];

  let plaguesPercentual = [];

  let dateFunc = new Date();

  if (aux2 < 1) {
    if (localStorage.getItem("registers-to-invite")) {
      axios
        .post(
          "https://sf.sipremo.com/api/acm/registry/registry",
          JSON.parse(localStorage.getItem("registers-to-invite"))
        )
        .then((res) => {
          notifySuccess("Registro cadastrados offline concluídos com sucesso!");
          localStorage.removeItem("registers-to-invite");
        })
        .catch((err) => {
          notifyError(
            "Erro ao realizar o registro, verifique as informações novamente"
          );
        });
    }

    aux2 += 1;
  }

  let config = {
    year: "2024",
    month: (dateFunc.getMonth() + 1).toString(),
  };

  let getAllPlots = {
    query: {
      region: localStorage.getItem("region"),
      material: { $ne: "" },
    },
  };

  async function getPrediction() {
    if (navigator.onLine) {
      await axios
        .get(`https://sf.sipremo.com/acm/prediction`, { mode: "no-cors" })
        .then(({ data }) => {
          const uniqueOccurrences = data.reduce((acc, current) => {
            const existing = acc.find(
              (item) =>
                item.period === current.period &&
                item.plot === current.plot &&
                item.level === current.level
            );

            if (!existing) {
              acc.push(current);
            }

            let dados = [];

            let todosPlots = localStorage.getItem("allPlots");

            JSON.parse(todosPlots).map((plot) => {
              if (plot.material != "COLHEITA") {
                acc.map((dado) => {
                  if (
                    plot.plot === dado.plot &&
                    dado.region === plot.region &&
                    dado.region === localStorage.getItem("region")
                  ) {
                    dados.push(dado);
                  }
                });
              }
            });

            return dados;
          }, []);

          localStorage.setItem(
            "uniqueOccurrences",
            JSON.stringify(uniqueOccurrences)
          );

          setOccurrences(uniqueOccurrences);

          populateCounters(uniqueOccurrences);

          infestations(uniqueOccurrences);

          separationMunicipality(uniqueOccurrences);
        })
        .catch((err) => {
          notifyError(
            "Não foi possível carregar as informações corretamente, realize o login!"
          );
        });
    }

    if (!navigator.onLine) {
      let uniqueOccurrences = localStorage.getItem("uniqueOccurrences");
      let informations = [];

      let arrayOccurrences = JSON.parse(uniqueOccurrences);

      arrayOccurrences.forEach((occurrence) => {
        informations.push(occurrence);
      });

      setTimeout(() => {
        setOccurrences(informations);

        populateCounters(informations);

        infestations(informations);

        separationMunicipality(informations);
      }, 3000);
    }
  }

  async function populateCounters(data) {
    let low_counter = 0;
    let med_counter = 0;
    let high_counter = 0;
    let hectar_counter = 0;
    let newVector = [];

    await data.reduce((_, oc) => {
      if (oc.region === localStorage.getItem("region")) {
        const probability = parseInt(oc.prob);
        if (probability >= 15 && probability <= 30) {
          newVector.push(oc);
          low_counter += 1;
        } else if (probability >= 30.1 && probability <= 50.0) {
          med_counter += 1;
          newVector.push(oc);
        } else if (probability >= 50.1) {
          high_counter += 1;
          hectar_counter += oc.area;
          newVector.push(oc);
        }
      }
    }, []);

    newVector.sort(function (a, b) {
      return b.prob - a.prob;
    });

    localStorage.setItem("occurrences-sort", newVector);
    setOccurrencesSort(newVector);
    setAlto(high_counter);
    setMedio(med_counter);
    setBaixo(low_counter);
    setHectar(hectar_counter);
    setValidator(false);
  }

  async function infestations(data) {
    plaguesPercentual = [];

    plague_names.map((plague) => {
      searchInfestations(data, plague.name);
    });

    setPlagues(plaguesPercentual);
  }

  async function searchInfestations(data, plague) {
    let count = 0;
    let all_percentual = 0;

    data.map((dt) => {
      if (
        dt.plague === plague &&
        dt.region === localStorage.getItem("region")
      ) {
        count += 1;
        all_percentual += dt.prob;
      }
    });

    plaguesPercentual.push({
      percentual: all_percentual / count,
      plague: plague,
    });
  }

  async function separationMunicipality(data) {
    let municipios = [];

    await data.map((objeto) => {
      if (
        !municipios.includes(objeto.municipality) &&
        objeto.region === localStorage.getItem("region")
      ) {
        municipios.push(objeto.municipality);
      }
    });

    setMunicipality(municipios);
    alternateCamouflage(false);
  }

  useEffect(() => {
    getPrediction();
  }, []);

  async function alternateCamouflage(value) {
    setCamouflage(value);
  }

  const handleViewCards = () => {
    setShowCards(true);
  };

  async function handleChangeMunicipio(value) {
    setShowCards(false);
    let municipality = value.target.value;
    let plots = [];

    occurrencesSort.map((occurrence) => {
      if (
        occurrence.region === localStorage.getItem("region") &&
        (occurrence.municipality === municipality || occurrence.ugb === municipality || (occurrence && municipality === "Senador Nordestino"))
      ) {
        plots.push(occurrence);
      }
    });

    setPlots(plots);
    setEnablePlague(true);
  }

  async function handleChangePlague(value) {
    setPlagueSelected(value.target.value);
    setShowCards(false);
  }

  const notifyError = (text) => toast.error(text);
  const notifySuccess = (text) => toast.success(text);
  const notifyInfo = (text) => toast.info(text);

  return camouflage ? (
    <Camouflage />
  ) : plaguesPercentual && municipality ? (
    <div className={style["body-home"]}>
      <div className={style["percentual-home"]}>
        <div className={style["data-information"]}>
          <h2>{hectar}</h2>
          <h6>ha</h6>
        </div>
        <div className={style["text-information"]}>
          <p>Com previsão de infestação acima de 50%</p>
        </div>
      </div>

      <div className={style["status-bugs"]}>
        <div className={style["text-info"]}>
          Talhões suscetíveis por nível de infestação
        </div>

        <div className={style["status-info"]}>
          <div className={style["level-status"]}>
            <h2>{alto}</h2>
            <h6 style={{ color: "red" }}>Alta</h6>
            <RedBalls />
          </div>
          <div className={style["level-status"]}>
            <h2>{medio}</h2>
            <h6 style={{ color: "#FEC601" }}>Média</h6>
            <YellowBalls />
          </div>
          <div className={style["level-status"]}>
            <h2>{baixo}</h2>
            <h6 style={{ color: "green" }}>Baixo</h6>
            <GreenBalls />
          </div>
        </div>
      </div>

      {/* 
      -------------- Anotação --------------
      Lagarta Verde 
      Lagarta Parda 
      Lagarta Cachorrinho 
      Lagarta Marrom 
      Glena    
      Sarsina  
      Dyrphia   
      Nistalea  
      Hylesia  
      Sabulodes  
      Percevejo Bronzeado
      Besouro Amarelo
      Besouro Azul 
      Psilideo de Concha 
      */}

      <div className={style["bugs-qtd"]}>
        <div className={style["text-info"]}>
          Pragas com maior chance de infestção
        </div>

        <div>
          {plagues.map((plague) =>
            !isNaN(plague.percentual) && plague.percentual !== 0 ? (
              <div className={style["plague-informations"]}>
                <div>
                  {plague.plague ===
                  ("Lagarta Parda" ||
                    "Lagarta Verde" ||
                    "Lagarta Cachorrinho" ||
                    "Lagarta Marrom") ? (
                    <img
                      className={style["Images-plagues"]}
                      src={caterpillar}
                    />
                  ) : plague.plague ===
                    ("Glena" ||
                      "Sarsina" ||
                      "Dyrphia" ||
                      "Nistalea" ||
                      "Hylesia" ||
                      "Sabulodes") ? (
                    <img className={style["Images-plagues"]} src={moth} />
                  ) : plague.plague ===
                    ("Percevejo Bronzeado" ||
                      "Besouro Amarelo" ||
                      "Besouro Azul") ? (
                    <img className={style["Images-plagues"]} src={beetle} />
                  ) : (
                    <img
                      className={style["Images-plagues"]}
                      src={psilide}
                      style={{ marginBottom: "15px" }}
                    />
                  )}
                </div>

                <div className={style["text-plague"]}>{plague.plague}</div>

                <div className={style["barra"]}>
                  <div
                    className={style["barra-color"]}
                    style={
                      plague.percentual <= 30.0
                        ? {
                            width: `${plague.percentual}%`,
                            backgroundColor: "green",
                          }
                        : plague.percentual <= 50.0 && plague.percentual >= 30.1
                        ? {
                            width: `${plague.percentual}%`,
                            backgroundColor: "#FEC601",
                          }
                        : {
                            width: `${plague.percentual}%`,
                            backgroundColor: "red",
                          }
                    }
                  >
                    {plague.percentual.toFixed(1)} %
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )
          )}
        </div>
      </div>

      <div
        className={style["details"]}
        style={showCards ? {} : { marginBottom: "50px" }}
      >
        <div className={style["text-info"]}>Selecione para ver em Detalhes</div>

        <div className={style["select-container"]}>
          {localStorage.getItem("region") === "RCO" ? (
            <select
              className={style["select"]}
              onChange={(value) => handleChangeMunicipio(value)}
            >
              <option value={""}>Escolha uma municipio</option>
              <option value={"Abaete"}>Abaete</option>
              <option value={"Extrema"}>Extrema</option>
              <option value={"Garca"}>Garça</option>
              <option value={"Gerais"}>Gerais</option>
              <option value={"Ibitira"}>Ibitira</option>
              <option value={"Martinho Campos"}>Martinho Campos</option>
              <option value={"Quartel Geral"}>Quartel Geral</option>
              <option value={"Bom Despacho"}>Bom Despacho</option>
              <option value={"Junco"}>Junco</option>
              <option value={"Buriti"}>Buriti</option>
              <option value={"Retirão"}>Retirão</option>
              <option value={"Fazendinha"}>Fazendinha</option>
            </select>
          ) : localStorage.getItem("region") === "RNO" ? (
            <select
              className={style["select"]}
              onChange={(value) => handleChangeMunicipio(value)}
            >
              <option value={""}>Escolha uma municipio</option>
              <option value={"Carbonita"}>Carbonita</option>
              <option value={"Senador Nordestino"}>Senador Nordestino</option>
              <option value={"Estiva"}>Estiva</option>
              <option value={"Diamante"}>Diamante</option>
              <option value={"Forquilha"}>Forquilha</option>
              <option value={"Ticó"}>Ticó</option>
            </select>
          ) : (
            <select
              className={style["select"]}
              onChange={(value) => handleChangeMunicipio(value)}
            >
              <option value={""}>Escolha uma municipio</option>
              <option value={"Sao Jose do Goiabal"}>São José do Goiabal</option>
              <option value={"Cachoeira D'Anta"}>Cachoeira D'Anta</option>
              <option value={"Dioni­sio"}>Dioni­sio</option>
              <option value={"Mumbaça"}>Mumbaça</option>
              <option value={"Requerente"}>Requerente</option>
              <option value={"Santa Cruz"}>Santa Cruz</option>
              <option value={"Marlieria"}>Marlieria</option>
              <option value={"São Pedro dos Ferros"}>
                Sao Pedro dos Ferros
              </option>
              <option value={"São Pedro"}>São Pedro</option>
            </select>
          )}

          <select
            className={style["select"]}
            onChange={(value) => handleChangePlague(value)}
            disabled={!enablePlague}
          >
            <option value={""}>Escolha uma praga</option>
            {plague_names.map((plague, key) => (
              <option key={key} value={plague.name}>
                {plague.name}
              </option>
            ))}
          </select>

          {showCards && plots.length === 0 ? (
            <div className={style["no-result-card"]}>
              Nenhum resultado encontrado!
            </div>
          ) : (
            <></>
          )}

          <button className={style["button"]} onClick={handleViewCards}>
            Filtrar
          </button>
        </div>
      </div>

      {showCards ? (
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, Autoplay, FreeMode]}
          style={{ width: "90%", marginBottom: "25px" }}
          pagination={{
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: 10,
          }}
          slidesPerView={1}
          autoplay={{
            delay: 3000,
          }}
          freeMode={true}
        >
          {plots.map((plot, index) =>
            plot.plague === plagueSelected && plot.prob >= 15 ? (
              <SwiperSlide key={index}>
                <Card plot={plot} />
              </SwiperSlide>
            ) : plagueSelected === "" && plot.prob >= 15 ? (
              <SwiperSlide key={index}>
                <Card plot={plot} />
              </SwiperSlide>
            ) : (
              <></>
            )
          )}
        </Swiper>
      ) : (
        <></>
      )}
      <ToastContainer />
    </div>
  ) : (
    <Camouflage />
  );
}

export default BodyDashboard;
