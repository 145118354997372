import React, { useEffect, useState } from "react";
import style from "./plague.module.css";


function Plague({ plagueSelect }) {
  const [plague, setPlague] = useState("");
  const [camprRequired, setCampRequired] = useState(false);

  const plague_names = [
    { name: "Besouro Amarelo", scientific: "Costalimaita Ferruginea" },
    { name: "Besouro Azul", scientific: "Sternocolaspis Quatuord" },
    { name: "Dyrphia", scientific: "Dyrphia Rosacordis" },
    { name: "Glena", scientific: "Glena Bipennaria" },
    { name: "Hylesia", scientific: "Hylesia Sp" },
    { name: "Lagarta Cachorrinho", scientific: "Eupseudosoma Spp" },
    { name: "Lagarta Marrom", scientific: "Oxydia Vesulia" },
    { name: "Lagarta Parda", scientific: "Thyrinteina Arnobia" },
    { name: "Lagarta Verde", scientific: "Psorocampa Denticulata" },
    { name: "Nistalea", scientific: "Nystalea Nyseus" },
    { name: "Percevejo Bronzeado", scientific: "Thaumastocoris Peregrinus" },
    { name: "Psilideo de Concha", scientific: "Glycaspis Brimblecombei" },
    { name: "Sabulodes", scientific: "Sabulodes Caberata" },
    { name: "Sarsina", scientific: "Sarsina Spp" },
  ];
  const handleChangePlague = (value) => {
    setCampRequired(false);
    setPlague(value.target.value);
  };

  const handleClickSelectPlague = () => {
    if (plague === "") {
      setCampRequired(true);
    } else {
      plagueSelect(plague);
    }
  };

  return (
    <div className={style["card"]}>
      <div className={style["title-card"]}>Praga</div>
      <div className={style["sector-inputs"]}>
        <select
          className={style["input-information"]}
          value={plague}
          onChange={(value) => handleChangePlague(value)}
        >
          <option value={""}>Selecione a Praga monitorada</option>

          {plague_names.map((plague, index) => (
            <option key={index} value={JSON.stringify(plague)}>
              {plague.name}
            </option>
          ))}
        </select>
      </div>
      {camprRequired ? (
        <p style={{ color: "red", fontSize: "0.8rem" }}>Selecione uma Praga</p>
      ) : (
        <></>
      )}
      <div className={style["sector-inputs"]}>
        <button
          className={style["button-plague"]}
          onClick={handleClickSelectPlague}
        >
          Selecionar
        </button>
      </div>
    </div>
  );
}

export default Plague;
