import React, { useEffect, useState } from "react";
import style from "./total-informations.module.css";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

function TotalInformations({ samples, infestation }) {
  const [totalCaterpillar, setTotalCaterpillar] = useState(0);
  const [totalSheet, setTotalSheet] = useState(0);
  const [mediaSheet, setMediaSheet] = useState(0);
  const [percentual, setPercentual] = useState("");

  
  const [levelInfestation, setLevelInfestation] = useState("");

  function totalCaterpillarFunc() {
    let total = 0;
    samples.map((sample) => {
      total += parseInt(sample.numeroLagartas);
    });
    setTotalCaterpillar(total);
  }

  function totalSheetFunc() {
    let total = 0;
    samples.map((sample) => {
      total += parseInt(sample.numeroFolhas);
    });
    setTotalSheet(total);
  }

  function totalMediaFunc() {
    let sheets = 0;
    let caterpillar = 0;
    samples.map((sample) => {
      sheets += parseInt(sample.numeroFolhas);
      caterpillar += parseInt(sample.numeroLagartas);
    });

    const percentual = (caterpillar / sheets) * 100;
    setMediaSheet(percentual.toFixed(1));
    setPercentual(percentual.toFixed(0));

    percentual < 3.1
    ? setLevelInfestation("Baixo")
    : percentual >= 3.1 && percentual < 8.1
    ?  setLevelInfestation("Médio")
    :  setLevelInfestation("Alto");


    if (percentual < 3.1) {
      setLevelInfestation("Baixo");
      infestation({diagnostic: "Baixo", prob: parseFloat(percentual)});
    } else if (percentual >= 3.1 && percentual < 8.1) {
      setLevelInfestation("Médio");
      infestation({diagnostic: "Médio", prob: parseFloat(percentual)});
    } else {
      setLevelInfestation("Alto");
      infestation({diagnostic: "Alto", prob: parseFloat(percentual)});
    }
  }

  useEffect(() => {
    totalCaterpillarFunc();
    totalSheetFunc();
    totalMediaFunc();
  }, [samples]);

  return (
    <div>
      <div className={style["card"]}>
        <div className={style["group-informations"]}>
          <div className={style["total-caterpillar"]}>
            <div>Total de Lagartas </div>
            <p>{totalCaterpillar}</p>
          </div>
          <div className={style["total-sheet"]}>
            <div>Total de Folhas </div>
            <p>{totalSheet}</p>
          </div>
          <div className={style["total-media-sheet"]}>
            <div>Média de Lagartas por Planta</div>
            <p>{mediaSheet}</p>
          </div>
          <div className={style["total-percentual"]}>
            <div>Percentual Total de Desfolha</div>
            <p>{percentual}%</p>
          </div>
        </div>
      </div>
      <div className={style["card"]}>
        <div className={style["title-card"]}>Nível de Infestação</div>
        <div className={style["level-infestation"]}>
          <div
            style={
              mediaSheet < 0.0
                ? { display: "none" }
                : mediaSheet >= 0.0 && mediaSheet < 3.1
                ? { backgroundColor: "#358128" }
                : mediaSheet >= 3.1 && mediaSheet < 8.1
                ? { backgroundColor: "#FEC601" }
                : { backgroundColor: "red" }
            }

            className={style["status"]}
          >
            {levelInfestation}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TotalInformations;
