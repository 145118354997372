import React, { useEffect, useState } from "react";
import style from "./total-informations.module.css";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

function TotalInformations({ samples, infestation }) {
  const [totalPsilideo, setTotalPsilideo] = useState(0);
  const [mediaNinfasLife, setMediaNinfasLife] = useState(0);
  const [totalNinfasParasited, setTotalNinfasParasited] = useState(0);
  const [percentualNinfasParasited, setPercentualNinfasParasited] = useState(0);

  const [levelInfestation, setLevelInfestation] = useState("");

  function totalPsilideoFunc() {
    let totalNinfasLife = 0;
    let totalNinfasParasited = 0;

    samples.map((sample) => {
      totalNinfasLife += parseInt(sample.percentualNinfas);
      totalNinfasParasited += parseInt(sample.percentualNinfasParasited);
    });

    let total = totalNinfasLife + totalNinfasParasited;
    setTotalPsilideo(total);
  }

  function mediaNinfasLifeFunc() {
    let totalNinfasLife = 0;

    samples.map((sample) => {
      totalNinfasLife += parseInt(sample.percentualNinfas);
    });

    let total = totalNinfasLife / 5 / 10;

    setMediaNinfasLife(total.toFixed(1));
  }

  function totalNinfasParasitedFunc() {
    let totalNinfasParasited = 0;

    samples.map((sample) => {
      totalNinfasParasited += parseInt(sample.percentualNinfasParasited);
    });

    setTotalNinfasParasited(totalNinfasParasited);
  }

  function totalParasitedPlotFunc() {
    let ninfasLife = 0;
    let ninfasParasited = 0;

    samples.map((sample) => {
      ninfasLife += parseInt(sample.percentualNinfas);
      ninfasParasited += parseInt(sample.percentualNinfasParasited);
    });

    const percentual = (ninfasParasited / (ninfasLife + ninfasParasited)) * 100;
    if(percentual >= 0){

      setPercentualNinfasParasited(percentual.toFixed(0));
    }else{
      setPercentualNinfasParasited(0);
    }

    const diagnosis = ninfasLife/50

    if (diagnosis < 5.1) {
      setLevelInfestation("Baixo");
      infestation({diagnostic: "Baixo", prob: parseFloat(percentualNinfasParasited)});
    } else if (diagnosis >= 5.1 && diagnosis < 20.1) {
      setLevelInfestation("Médio");
      infestation({diagnostic: "Médio", prob: parseFloat(percentualNinfasParasited)});
    } else {
      setLevelInfestation("Alto");
      infestation({diagnostic: "Alto", prob: parseFloat(percentualNinfasParasited)});
    }
  }

  useEffect(() => {
    totalPsilideoFunc();
    mediaNinfasLifeFunc();
    totalNinfasParasitedFunc();
    totalParasitedPlotFunc();
  }, [samples]);

  return (
    <div>
      <div className={style["card"]}>
        <div className={style["group-informations"]}>
          <div className={style["total-caterpillar"]}>
            <div>Total de Ninfas Vivas </div>
            <p>{totalPsilideo}</p>
          </div>
          <div className={style["total-sheet"]}>
            <div>Média de Ninfas Vivas por Folha </div>
            <p>{mediaNinfasLife}</p>
          </div>
          <div className={style["total-media-sheet"]}>
            <div>Total de Ninfas Parasitadas</div>
            <p>{totalNinfasParasited}</p>
          </div>
          <div className={style["total-percentual"]}>
            <div>Parasitismo no Talhão</div>
            <p>{percentualNinfasParasited}%</p>
          </div>
        </div>
      </div>
      <div className={style["card"]}>
        <div className={style["title-card"]}>Nível de Infestação</div>
        <div className={style["level-infestation"]}>
          <div
            style={
              mediaNinfasLife < 0.0
                ? { display: "none" }
                : mediaNinfasLife >= 0.0 &&
                  mediaNinfasLife < 5.1
                ? { backgroundColor: "#358128" }
                : mediaNinfasLife >= 5.1 &&
                  mediaNinfasLife < 20.1
                ? { backgroundColor: "#FEC601" }
                : { backgroundColor: "red" }
            }
            className={style["status"]}
          >
            {levelInfestation}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TotalInformations;
