import React, { useEffect, useState } from "react";
import style from "./body-register.module.css";
import DataBasic from "./data-basic/data-basic";
import Area from "./area/area";
import Plague from "./plague/plague";
import CaterpillarPlague from "./caterpilar-plague/caterpilar-plague";
import BeetlePlague from "./beetle-plague/beetle-plague";
import PsilideoPlague from "./psilideo-plague/psilideo-plague";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

let registers = [];
let aux = 0;

function BodyRegister() {
  const [informationPlague, setInformationPlague] = useState({});
  const [containPlague, setContainPlague] = useState(false);
  const [dataBasic, setDataBasic] = useState({});
  const [informationArea, setInformationArea] = useState({});
  const [levelInfestation, setLevelInfestation] = useState({});

  const [user, setUser] = useState(localStorage.getItem("nameUser"));

  if (aux < 1) {
    localStorage.removeItem("register-plot-alternate");
    if (localStorage.getItem("registers-to-invite")) {
      axios
        .post(
          "https://sf.sipremo.com/api/acm/registry/registry",
          JSON.parse(localStorage.getItem("registers-to-invite"))
        )
        .then((res) => {
          // notifySuccess("Registro cadastrados offline concluídos com sucesso!");
          localStorage.removeItem("registers-to-invite");
        })
        .catch((err) => {
          // notifyError(
          //   "Erro ao realizar o registro, verifique as informações novamente"
          // );
          localStorage.removeItem("registers-to-invite");
        });
    }

    aux += 1;
  }

  const handleChangeInformationArea = (updateInformationArea) => {
    let registers = JSON.parse(localStorage.getItem("uniqueOccurrences"));

    registers.map((register) => {
      if (updateInformationArea.plot === register.plot) {
        localStorage.setItem(
          "register-plot-alternate",
          JSON.stringify(register)
        );
      }
    });

    setInformationArea(updateInformationArea);
  };

  const handleChangeInformationPlague = (updateInformationPlague) => {
    setInformationPlague(JSON.parse(updateInformationPlague));
    setContainPlague(true);
  };

  const handleChangeInformationDataBasic = (updatedInformationBasic) => {
    setDataBasic(updatedInformationBasic);
  };

  const handleChangeInfestation = (information) => {
    setLevelInfestation(information);
  };

  function getDate() {
    let date = new Date();
    let year = date.getFullYear().toString();
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();
    return `${year.padStart(4, "0")}-${month.padStart(2, "0")}-${day.padStart(
      2,
      "0"
    )}`;
  }

  async function handleClickSaveRegister() {
    let monitor = localStorage.getItem("user")
    if (navigator.onLine) {


          let config = [
            {
              date: getDate(),
              monitor: JSON.parse(monitor).user,
              region: dataBasic.region,
              plot: informationArea.plot,
              plague_type:
                informationPlague.name === "Lagarta Parda" ||
                informationPlague.name === "Lagarta Verde" ||
                informationPlague.name === "Lagarta Cachorrinho" ||
                informationPlague.name === "Lagarta Marrom" ||
                informationPlague.name === "Glena" ||
                informationPlague.name === "Sarsina" ||
                informationPlague.name === "Dyrphia" ||
                informationPlague.name === "Nistalea" ||
                informationPlague.name === "Hylesia" ||
                informationPlague.name === "Sabulodes"
                  ? "caterpillar"
                  : informationPlague.name === "Besouro Amarelo" ||
                    informationPlague.name === "Besouro Azul"
                  ? "beetle"
                  : informationPlague.name === "Psilideo de Concha" ||
                    informationPlague.name === "Percevejo Bronzeado"
                  ? "psilide"
                  : "",
              plague_name: informationPlague.name,
              scientific_name: informationPlague.scientific,
              diagnosis: levelInfestation.diagnostic,
              prob: parseFloat((levelInfestation.prob).toFixed(2)),
            },
          ];


      await axios
        .post("https://sf.sipremo.com/api/acm/registry/registry", config)
        .then((res) => {
          notifySuccess("Registro cadastrado com sucesso!");
        })
        .catch((err) => {
          notifyError(
            "Erro ao realizar o registro, verifique as informações novamente"
          );
        });
    }
    

    if (!navigator.onLine) {
      let config = {
        date: getDate(),
        monitor: user,
        region: dataBasic.region,
        plot: informationArea.plot,
        plague_type:
          informationPlague.name === "Lagarta Parda" ||
          informationPlague.name === "Lagarta Verde" ||
          informationPlague.name === "Lagarta Cachorrinho" ||
          informationPlague.name === "Lagarta Marrom" ||
          informationPlague.name === "Glena" ||
          informationPlague.name === "Sarsina" ||
          informationPlague.name === "Dyrphia" ||
          informationPlague.name === "Nistalea" ||
          informationPlague.name === "Hylesia" ||
          informationPlague.name === "Sabulodes"
            ? "caterpillar"
            : informationPlague.name === "Besouro Amarelo" ||
              informationPlague.name === "Besouro Azul"
            ? "beetle"
            : informationPlague.name === "Psilideo de Concha" ||
              informationPlague.name === "Percevejo Bronzeado"
            ? "psilide"
            : "",
        plague_name: informationPlague.name,
        scientific_name: informationPlague.scientific,
        diagnosis: levelInfestation.diagnostic,
        prob: levelInfestation.prob,
      };


      registers.push(config);
      localStorage.setItem("registers-to-invite", JSON.stringify(registers));
      notifyInfo("Registro cadastrado com sucesso!");
    }
  }

  const notifyError = (text) => toast.error(text);
  const notifySuccess = (text) => toast.success(text);
  const notifyInfo = (text) => toast.info(text);
  const notifyWarning = (text) => toast.warning(text);

  return (
    <div className={style["body-home"]}>
      <div className={style["title-register"]}>Registro de Pragas</div>
      <DataBasic dataBasic={handleChangeInformationDataBasic} />
      <Area areaInformations={handleChangeInformationArea} />
      <Plague plagueSelect={handleChangeInformationPlague} />
      {informationPlague.name === "Lagarta Parda" ||
      informationPlague.name === "Lagarta Verde" ||
      informationPlague.name === "Lagarta Cachorrinho" ||
      informationPlague.name === "Lagarta Marrom" ||
      informationPlague.name === "Glena" ||
      informationPlague.name === "Sarsina" ||
      informationPlague.name === "Dyrphia" ||
      informationPlague.name === "Nistalea" ||
      informationPlague.name === "Hylesia" ||
      informationPlague.name === "Sabulodes" ? (
        <CaterpillarPlague
          plague={informationPlague}
          infestation={handleChangeInfestation}
        />
      ) : informationPlague.name === "Besouro Amarelo" ||
        informationPlague.name === "Besouro Azul" ? (
        <BeetlePlague
          plague={informationPlague}
          infestation={handleChangeInfestation}
        />
      ) : informationPlague.name === "Psilideo de Concha" ||
        informationPlague.name === "Percevejo Bronzeado" ? (
        <PsilideoPlague
          plague={informationPlague}
          infestation={handleChangeInfestation}
        />
      ) : (
        <></>
      )}
      {containPlague ? (
        <button className={style["button"]} onClick={handleClickSaveRegister}>
          Enviar
        </button>
      ) : (
        <></>
      )}

      <ToastContainer />
    </div>
  );
}

export default BodyRegister;
