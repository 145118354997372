import React, { useEffect, useState } from "react";
import style from "./caterpilar-plague.module.css";

import CaterPillarIamge from "../../../images/caterpillar-colored.png";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import TotalInformations from "./total-informations/total-informations";

function CaterpillarPlague({ plague, infestation }) {
  const [samples, setSamples] = useState([]);
  
  const [levelInfestation, setLevelInfestation] = useState("");

  useEffect(() => {
    const amostra = Array.from({ length: 9 }).map((_, index) => ({
      planta: index + 1,
      numeroFolhas: 100,
      numeroLagartas: 0,
      percentualDesfolha: "",
    }));
    setSamples(amostra);
  }, []);

  const handleChangeNumeroFolhas = (index, value) => {
    const updatedAmostras = [...samples];
    updatedAmostras[index].numeroFolhas = value;
    setSamples(updatedAmostras);
  };

  const handleChangeNumeroLagartas = (index, value) => {
    const updatedAmostras = [...samples];
    updatedAmostras[index].numeroLagartas = value;
    setSamples(updatedAmostras);
  };

  const calcularPercentualDesfolha = (index) => {
    const updatedAmostras = [...samples];

    const amostra = samples[index];
    const percentual = (amostra.numeroLagartas / amostra.numeroFolhas) * 100;

    updatedAmostras[index].percentualDesfolha = percentual.toFixed(0);
    return percentual.toFixed(0);
  };

  const handleClickAddCaterpillar = (index) => {
    const updatedAmostras = [...samples];
    updatedAmostras[index].numeroLagartas += 1;
    setSamples(updatedAmostras);
  };

  const handleClickRemoveCaterpillar = (index) => {
    const updatedAmostras = [...samples];
    updatedAmostras[index].numeroLagartas -= 1;
    if (updatedAmostras[index].numeroLagartas < 0) {
      updatedAmostras[index].numeroLagartas = 0;
    }
    setSamples(updatedAmostras);
  };

  const handleChangeInfestation = (information) => {
    let info = information;

    infestation(info);

  }

  return (
    <div>
      <div className={style["card"]}>
        <div className={style["title-card"]}>Amostras</div>
        <div className={style["sample-plague"]}>
          <img src={CaterPillarIamge} />
          <div className={style["name-sample-plague"]}>
            <div>{plague.name}</div>
            <div>{plague.scientific}</div>
          </div>
        </div>

        <div className={style["samples"]}>
          {samples.map((sample, index) => (
            <div className={style["collected-sample-title"]}>
              <div className={style["collected-sample-plant"]}>
                <div className={style["collected-sample-plant-number"]}>
                  {index + 1}
                </div>
                <div className={style["collected-sample-plant-text"]}>
                  Planta
                </div>
              </div>

              <div className={style["collected-sample-plant-caterpillar"]}>
                <div>
                  <div className={style["collected-sample-plant-number-sheet"]}>
                    N° de Folhas
                  </div>
                  <input
                    className={
                      style["collected-sample-plant-number-sheet-input"]
                    }
                    value={sample.numeroFolhas}
                    onChange={(e) =>
                      handleChangeNumeroFolhas(index, e.target.value)
                    }
                    type="number"
                  ></input>
                </div>
                <div>
                  <div
                    className={
                      style["collected-sample-plant-number-caterpillar"]
                    }
                  >
                    N° de Lagartas
                  </div>
                  <div>
                    <RemoveIcon
                      onClick={(e) => handleClickRemoveCaterpillar(index)}
                      className={style["icon"]}
                    />
                    <input
                      className={
                        style["collected-sample-plant-number-caterpillar-input"]
                      }
                      value={sample.numeroLagartas}
                      onChange={(e) =>
                        handleChangeNumeroLagartas(index, e.target.value)
                      }
                      min={0}
                      type="number"
                    ></input>
                    <AddIcon
                      onClick={(e) => handleClickAddCaterpillar(index)}
                      className={style["icon"]}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div
                  className={style["collected-sample-plant-number-percentual"]}
                >
                  Percentual de Desfolha:{" "}
                  <div className={style["percentual"]}>
                    {calcularPercentualDesfolha(index)}%
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <TotalInformations samples={samples}  infestation={handleChangeInfestation} />

     
    </div>
  );
}

export default CaterpillarPlague;
